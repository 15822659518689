<template>
    <div>
        <div class="card-tm">
            <div class="card">
                <div class="card-body gradiant-bg3 py-4 text-center">
                    <h6 class="wallet-title text-white">Tradehit Stake & Earn</h6>
                    <p class="font-light-gray2">Start holdings and earn more coins</p>
                </div>
            </div>
        </div>
        <div class="container staking py-4">
            <!-- HEADING -->
            <div class="col-xl-6 col-lg-4 col-md-5">
                <div class="staking-header d-flex justify-content-between">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(item, index) in tabItems" :key="index">
                            <button class="nav-link" :class="item == selected ? 'active' : ''" @click="change_tab(item)"
                                :id="`pills-${item.toLowerCase()}-list-tab`" data-bs-toggle="pill"
                                :data-bs-target="`#pills-${item.toLowerCase()}-list`" type="button" role="tab"
                                :aria-controls="`pills-${item.toLowerCase()}-list`" aria-selected="true">{{ item }}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- table start here -->


            <tbody v-if="skeletonLoad">
                <tr class="text-center align-middle" v-for="i in 4" :key="i">
                    <td class="text-uppercase">
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>
                    <td class="text-uppercase">
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>
                    <td class="text-capitalize">
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>
                    <td>
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>
                    <td>
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>
                    <td>
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>

                    <td>
                        <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" style="margin:0 auto;" />
                    </td>
                </tr>

            </tbody>

            <div class="row row-cols-3" v-if="Stake_currencyData.length != 0 && !skeletonLoad">

                <div class="col card-tm" v-for="(data, index) in Stake_currencyData" :key="index">
                    <div class="card shadow rounded-4">
                        <div class="card-body">
                            <div class="row gy-3">
                                <div class="col-12">
                                    <div class="gap-3 d-flex align-items-center">
                                        <img :src="data.stake_currency_image" class="rounded-circle bg-light shadow-sm p-2"
                                            alt="icon" style="height:40px">
                                        <div class="d-flex justify-content-between flex-wrap w-100">
                                            <h6 class="fs-5 m-0 fw-bold"> {{ data.stake_currency }}</h6>
                                            <div>
                                                <p v-if="data.s_data[`${data.selected_plan_type}-${data.s_maturity_days}`]">
                                                    Min {{
                                                        data.s_data[`${data.selected_plan_type}-${data.s_maturity_days}`].min_stake_amount
                                                    }}
                                                </p>
                                                <p v-else>Min {{
                                                    data.s_data[`${data.selected_plan_type}-${reset(data)}`].min_stake_amount
                                                }}
                                                </p>

                                                <p v-if="data.s_data[`${data.selected_plan_type}-${data.s_maturity_days}`]">
                                                    Max {{
                                                        data.s_data[`${data.selected_plan_type}-${data.s_maturity_days}`].max_stake_amount
                                                    }}
                                                </p>
                                                <p v-else> Max {{
                                                    data.s_data[`${data.selected_plan_type}-${reset(data)}`].max_stake_amount
                                                }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row align-items-center ">
                                        <div class="col">
                                            <span class="fw-600 up fs-5"
                                                v-if="data.s_data[`${data.selected_plan_type}-${data.s_maturity_days}`]">
                                                {{
                                                    data.s_data[`${data.selected_plan_type}-${data.s_maturity_days}`].roi_percentage
                                                }}%
                                            </span>
                                            <span v-else>{{
                                                data.s_data[`${data.selected_plan_type}-${reset(data)}`].roi_percentage }}%
                                            </span>
                                            <p><small class="fs-12">Reference APY</small></p>
                                        </div>
                                        <div class="col">
                                           
                                        </div>
                                        <div class="col">

                                            <div class="text-end subscribe-btn">
                                                <button type="button" v-if="isLoggedIn" @click="setmodeldata(data)"
                                                    class="btn btn-primary rounded-pill" data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal">Subscribe</button>
                                                <button type="button" v-else class="btn btn-primary rounded-pill"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal">Subscribe</button>

                                                <!-- Modal -->
                                                <div class="modal fade" id="exampleModal" tabindex="-1"
                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg modal-dialog-centered">
                                                        <div class="modal-content">
                                                            <div class="modal-body">
                                                                <!-- left modal -->
                                                                <form @submit.prevent="submitForm">
                                                                    <div class="row">

                                                                        <div class="col-12 col-lg-6 text-start">
                                                                            <!-- title -->
                                                                            <div class="row">
                                                                                <div class="col text-start">
                                                                                    <p class="fs-5">Subscribe to <span
                                                                                            class="text-dark-yellow">{{
                                                                                                subsData.stake_currency
                                                                                            }}</span></p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="card border-0">
                                                                                <div class="card-body p-0">
                                                                                    <!-- main row -->
                                                                                    <div class="col">
                                                                                        <!-- days row heading -->
                                                                                        <div class="row">
                                                                                            <div class="col text-start">
                                                                                                <h6>Duration (Days)</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- days row btn -->
                                                                                        <div class="row">
                                                                                            <div class="col">
                                                                                                <div
                                                                                                    class="d-flex flex-wrap gap-2 align-items-center">

                                                                                                    <ul
                                                                                                        class=" typelist d-flex locked-btn1 justify-content-between gap-2 list-unstyled">
                                                                                                        <li v-for="(type, i) in subsData.a_plan_types"
                                                                                                            :key="i">
                                                                                                            <button id="b1"
                                                                                                                class="btn"
                                                                                                                type="button"
                                                                                                                :class="type == subsData.selected_plan_type ? 'plan_active' : ''
                                                                                                                "
                                                                                                                @click="(subsData.selected_plan_type = type), (set_id(subsData.selected_plan_type, subsData))">
                                                                                                                {{ type }}
                                                                                                            </button>

                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    <div :v-if="subsData.o_plan_days"
                                                                                                        v-for="(day, i) in NullishCoalescing(subsData.o_plan_days, subsData.selected_plan_type)"
                                                                                                        :key="i"
                                                                                                        @click="(subsData.s_maturity_days = day), (set_id(subsData.selected_plan_type, subsData, subsData.s_maturity_days))">
                                                                                                        <button id="b1"
                                                                                                            class="btn"
                                                                                                            type="button"
                                                                                                            :class="day == subsData.s_maturity_days ? 'day_active' : ''">{{
                                                                                                                day }} </button>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!-- subscription amount -->
                                                                                        <div class="row">
                                                                                            <div class="col">
                                                                                                <div class="row mt-3">
                                                                                                    <div class="col">
                                                                                                        <div class="mb-3">
                                                                                                            <label
                                                                                                                for="exampleInputEmail1"
                                                                                                                class="form-label d-flex align-items-center justify-content-between">
                                                                                                                <h6
                                                                                                                    class="fw-semibold mb-0">
                                                                                                                    Subscription
                                                                                                                    amount
                                                                                                                </h6>
                                                                                                                <span
                                                                                                                    class="text-primary fw-bold"
                                                                                                                    style="cursor:pointer;"
                                                                                                                    id="basic-addon3"
                                                                                                                    @click="setAmount()">MAX</span>
                                                                                                            </label>
                                                                                                            <div
                                                                                                                class="form-group">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    class="form-control p-3 px-2"
                                                                                                                    v-model="amount"
                                                                                                                    placeholder="Please enter the amount"
                                                                                                                    id="basic-url"
                                                                                                                    aria-describedby="basic-addon3"
                                                                                                                    onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))">

                                                                                                            </div>


                                                                                                            <div class="input-errors"
                                                                                                                v-if="v$.amount.$errors[0]">
                                                                                                                <div
                                                                                                                    class="error">
                                                                                                                    {{
                                                                                                                        v$.amount.$errors[0]
                                                                                                                        ?
                                                                                                                        v$.amount.$errors[0].$message
                                                                                                                        : "" }}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <!-- subsciption dis -->
                                                                                                <div class="row ">
                                                                                                    <div class="col ">
                                                                                                        <h6><span
                                                                                                                class="fw-semibold">{{
                                                                                                                    (available_amt)
                                                                                                                    ?
                                                                                                                    available_amt
                                                                                                                    : 0 }}
                                                                                                                {{
                                                                                                                    subsData.stake_currency
                                                                                                                }} </span>
                                                                                                            Available
                                                                                                        </h6>
                                                                                                    </div>

                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="">
                                                                                            <h6
                                                                                                class="fw-semibold mt-3 mb-1">
                                                                                                Amount
                                                                                                Limitation</h6>
                                                                                            <div class="row">

                                                                                                <div class="col">
                                                                                                    <span
                                                                                                        class="fs-14 lead">Minimum
                                                                                                    </span>
                                                                                                    <span class="fs-14">{{
                                                                                                        min_stake_amount }}
                                                                                                        {{
                                                                                                            subsData.stake_currency
                                                                                                        }}</span>
                                                                                                </div>
                                                                                                <div class="col">
                                                                                                    <span
                                                                                                        class="fs-14 lead">Maximum</span>
                                                                                                    <span class="fs-14 "> {{
                                                                                                        max_stake_amount }}
                                                                                                        {{
                                                                                                            subsData.stake_currency
                                                                                                        }}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- convert -->
                                                                                        <a :href="'/wallet/deposit?symbol=' + subsData.stake_currency"
                                                                                            class="row convert mt-2 bg-light-yellow rounded-3 align-items-center">
                                                                                            <div
                                                                                                class="col-8 align-items-center d-flex justify-content-start p-3">
                                                                                                <span><img
                                                                                                        src="img/info.svg"
                                                                                                        alt=""></span><span
                                                                                                    class="text-dark-yellow">
                                                                                                    Don’t
                                                                                                    have enough
                                                                                                    crypto?</span>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-4 align-items-center justify-content-center justify-content-xl-end d-flex gap-2">
                                                                                                <span
                                                                                                    class="text-dark-yellow"
                                                                                                    type="button">Deposit</span>
                                                                                                <span><img
                                                                                                        src="img/right.svg"
                                                                                                        alt=""></span>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <!-- right modal -->
                                                                        <div class="col-12 col-lg-6">

                                                                            <!-- title -->
                                                                            <div class="row">
                                                                                <div class="col text-start">
                                                                                    <p class="fs-5">Summary</p>
                                                                                </div>

                                                                                <div class="col-2">
                                                                                    <i data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                        class="fs-5 fa-regular fa-circle-xmark"
                                                                                        type="button"></i>
                                                                                </div>



                                                                            </div>

                                                                            <div
                                                                                class="card bg-light-yellow text-start border-0 rounded-3">
                                                                                <div class="card-body">

                                                                                    <!-- details -->
                                                                                    <div class="row">
                                                                                        <div class="col px-2 p-0">

                                                                                            <div
                                                                                                class="row justify-content-between">
                                                                                                <div
                                                                                                    class="col d-flex gap-2 align-items-center">
                                                                                                    <i
                                                                                                        class="fa-solid fa-caret-right"></i>
                                                                                                    <h6>
                                                                                                        Subscription Date:
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col subscribe-dates text-end">
                                                                                                    <h6>{{ showdate(new
                                                                                                        Date()) }}
                                                                                                    </h6>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                class="row justify-content-between">
                                                                                                <div
                                                                                                    class="col d-flex gap-2 align-items-center">
                                                                                                    <i
                                                                                                        class="fa-solid fa-caret-right"></i>
                                                                                                    <h6>
                                                                                                        Value Date:</h6>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col subscribe-dates text-end">
                                                                                                    <h6>{{ showdate(new
                                                                                                        Date()) }}
                                                                                                    </h6>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                class="row justify-content-between">
                                                                                                <div
                                                                                                    class="col d-flex gap-2 align-items-center">
                                                                                                    <i
                                                                                                        class="fa-solid fa-caret-right"></i>
                                                                                                    <h6>
                                                                                                        Intrest End Date:
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col subscribe-dates text-end">
                                                                                                    <h6> {{
                                                                                                        parsedate(NullishCoalescing(subsData.s_data,
                                                                                                            `${subsData.selected_plan_type}-${subsData.s_maturity_days}`).maturity_days)
                                                                                                    }}
                                                                                                    </h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="row justify-content-between">
                                                                                                <div
                                                                                                    class="col d-flex gap-2 align-items-center">
                                                                                                    <i
                                                                                                        class="fa-solid fa-caret-right"></i>
                                                                                                    <h6>
                                                                                                        Redemption Period:
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col subscribe-dates text-end">
                                                                                                    <h6> 2 Days</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="row justify-content-between">
                                                                                                <div
                                                                                                    class="col d-flex gap-2 align-items-center">
                                                                                                    <i
                                                                                                        class="fa-solid fa-caret-right"></i>
                                                                                                    <h6>
                                                                                                        Redemption Date:
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col subscribe-dates text-end">
                                                                                                    <h6> {{
                                                                                                        parsedate(NullishCoalescing(subsData.s_data,
                                                                                                            `${subsData.selected_plan_type}-${subsData.s_maturity_days}`).maturity_days
                                                                                                            + 2) }}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="row justify-content-between">
                                                                                                <div
                                                                                                    class="col d-flex gap-2 align-items-center">
                                                                                                    <i
                                                                                                        class="fa-solid fa-caret-right"></i>
                                                                                                    <h6>
                                                                                                        Interest Period:
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col subscribe-dates text-end">
                                                                                                    <h6>{{
                                                                                                        Interest_period(NullishCoalescing(subsData.s_data,
                                                                                                            `${subsData.selected_plan_type}-${subsData.s_maturity_days}`).roi_interval)
                                                                                                    }}
                                                                                                    </h6>
                                                                                                </div>
                                                                                            </div>

                                                                                            <!--div
                                                                                    class="row justify-content-between">
                                                                                    <div
                                                                                        class="col d-flex gap-2 align-items-center">
                                                                                        <i
                                                                                            class="fa-solid fa-caret-right"></i>
                                                                                        <h6>
                                                                                            Next Subscription Date:</h6>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col subscribe-dates text-end">
                                                                                        <h6>2022-10-21 09:29</h6>
                                                                                    </div>
                                                                                </div-->

                                                                                        </div>
                                                                                    </div>

                                                                                    <!-- est -->
                                                                                    <div class="row mt-3">
                                                                                        <div class="col">
                                                                                            <div class="row">
                                                                                                <div class="col">

                                                                                                    <div
                                                                                                        class="row justify-content-between">
                                                                                                        <div
                                                                                                            class="col d-flex gap-2 align-items-center">

                                                                                                            <h6>
                                                                                                                Est. APR
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="col text-end">
                                                                                                            <h6 class="up">
                                                                                                                <span
                                                                                                                    class="green-text">{{
                                                                                                                        NullishCoalescing(subsData.s_data,
                                                                                                                            `${subsData.selected_plan_type}-${subsData.s_maturity_days}`).roi_percentage
                                                                                                                    }}%</span>
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="row justify-content-between">
                                                                                                        <div
                                                                                                            class="col d-flex gap-2 align-items-center">

                                                                                                            <h6>
                                                                                                                Est.
                                                                                                                Interest
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="col text-end">
                                                                                                            <h6 class="up">
                                                                                                                --
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                    <!-- check -->
                                                                                    <div class="row mt-2">
                                                                                        <div class="col">
                                                                                            <div class="form-check">
                                                                                                <input
                                                                                                    class="form-check-input"
                                                                                                    type="checkbox"
                                                                                                    v-model="terms" value=""
                                                                                                    id="flexCheckDefault">
                                                                                                <label
                                                                                                    class="form-check-label"
                                                                                                    for="flexCheckDefault">
                                                                                                    <h6>I have read and I
                                                                                                        agree to
                                                                                                        Tradehit Simple Earn
                                                                                                        Service
                                                                                                        Agreement</h6>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="input-errors">
                                                                                        <div class="error ">{{
                                                                                            v$.terms.$errors[0] ?
                                                                                            v$.terms.$errors[0].$message :
                                                                                            "" }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- submit -->

                                                                                    <div class="row mt-2">
                                                                                        <div class="col">
                                                                                            <button type="submit"
                                                                                                v-if="loading"
                                                                                                class="btn btn-action w-100">Confirm</button>
                                                                                            <button
                                                                                                class="subscribe-btn w-100 my-3"
                                                                                                type="button" v-else>
                                                                                                <span
                                                                                                    class="spinner-grow spinner-grow-sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"></span>
                                                                                                Loading...
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </form>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- modal ends -->

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                          
                        </div>
                        <div class="card-footer ">  <button type="button" class="btn"
                                            :class="plan == data.selected_plan_type ? 'plan_active border-0' : ''"
                                            v-for="(plan, i) in data.a_plan_types" :key="i"
                                            @click="data.selected_plan_type = plan">{{ plan == 'flexible' ?
                                                'Flexible ' : 'Fixed' }}
                                        </button>
                                        <span class="btn stake-days"
                                            :class="day == data.s_maturity_days ? 'day_active border-0' : ''"
                                            v-for="(day, i) in data.o_plan_days[data.selected_plan_type]" :key="i"
                                            @click="data.s_maturity_days = day">{{ day }}
                                        </span></div>
                    </div>


                </div>
            </div>
            <tbody v-else-if="Stake_currencyData.length == 0 && !skeletonLoad">
                <tr>
                    <td colspan="7">
                        <div class="d-flex border-0 flex-column text-center justify-content-center py-4">
                            <div>
                                <img width="70" :src="require(`../../assets/images/icons/no-record.svg`)" />
                            </div>
                            <p class="fw-500 fs-14">No record found</p>
                        </div>
                    </td>
                </tr>
            </tbody>

            <div class="p-2 text-end">
                <pagination v-model="page" :records="totalData" :per-page="perPageData" :options="options"
                    @paginate="getStakings" />

            </div>
        </div>



    </div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import ApiClass from "@/api/api";
import _ from "lodash";
import VueSkeletonLoader from "skeleton-loader-vue";
import useVuelidate from "@vuelidate/core";
import {
    required,
    helpers,
    sameAs,
    maxValue,
    between,
} from "@vuelidate/validators";
export default {
    name: 'StakingView',
    components: {
        VueSkeletonLoader,
        Pagination
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            loggedIn: false,
            page: 1,
            per_page: 10,
            totalData: 0,
            perPageData: 0,
            options: {
                edgeNavigation: false,
                chunksNavigation: false,
                chunk: 3,
                texts: false,
                format: false,
            },
            loading: true,
            plan_type: "",
            search: "",
            AllStaking: [],
            skeletonLoad: false,
            allData: [],
            selected: "All",
            available_amt: "",
            tabItems: ["All", "Fixed", "Flexible"],
            subsData: "",
            clickedData: "",
            typevalue: "",
            amount: "",
            vdefault: "",
            terms: "",
            staking_plan_id: "",
            max_stake_amount: "",
            min_stake_amount: "",
            isLoggedIn: false,
            total_stake: 0,
            total_withdrawable: 0
        }
    },
    validations() {
        return {
            amount: {
                required: helpers.withMessage("Amount is required", required),
                maxValue: helpers.withMessage("Amount should be less than your wallet balance", maxValue(this.available_amt)),
                between: helpers.withMessage(`Amount should be between ${this.min_stake_amount} and ${this.max_stake_amount}`,
                    between(this.min_stake_amount, this.max_stake_amount)),
            },
            terms: {
                sameAs: helpers.withMessage("Please Agree Staking Services Agreement.", sameAs(true)),
            },
        };
    },
    mounted() {
        if (localStorage.getItem("token")) {
            this.loggedIn = true;
        }
        var arr = ["a"];
        _.map(arr, (v) => console.log(v));
        this.$store.getters.isLoggedIn == true ? this.isLoggedIn = true : this.isLoggedIn = false;
        this.getStakings(1);
        this.getstakeWalletBalance();
    },
    computed: {
        Stake_currencyData() {
            var vm = this;
            return this.AllStaking?.filter((data) => {
                return data.stake_currency.toLowerCase().indexOf(vm.search.toLowerCase()) !== -1;
            });

        },
    },
    methods: {
        change_tab(tab) {
            this.selected = tab;
            if (tab == "Fixed") {
                this.plan_type = "fixed"
                this.page = 1;
                this.per_page = 10;
                this.totalData = 0;
                this.perPageData = 0;
                this.getStakings(1)
            }

            if (tab == "Flexible") {
                this.plan_type = "flexible"
                this.page = 1;
                this.per_page = 10;
                this.totalData = 0;
                this.perPageData = 0;
                this.getStakings(1)
            }

            if (tab == "All") {
                this.plan_type = ""
                this.page = 1;
                this.per_page = 10;
                this.totalData = 0;
                this.perPageData = 0;
                this.getStakings(1)
            }
        },
        changeonSearch() {
            this.getStakings(1);
        },
        reset(data) {
            return (data.s_maturity_days = data.o_plan_days[data.selected_plan_type][0]);
        },

        async getStakings(page) {
            const response = await ApiClass.getNodeRequest("staking/get?per_page=" + this.per_page + "&page=" + page + "&plan_type=" + this.plan_type + "&search=" + this.search, true);
            this.skeletonLoad = true;
            if (response.data.status_code == 1) {
                this.skeletonLoad = false;
                this.allData = this.AllStaking = response.data.data.data;
                this.totalData = response.data.data.total;
                this.perPageData = response.data.data.per_page;

            }
        },

        async submitForm() {
            const result = await this.v$.$validate();
            if (!result) {
                return;
            }
            this.loading = false;
            let form_data = {
                staking_plan_id: this.staking_plan_id,
                amount: this.amount,
            };

            let response = await ApiClass.postNodeRequest("staking/subscribe", true, form_data);

            if (response.data.status_code == 1) {
                this.$swal({
                    position: "top-end",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 3000,
                });
                //empty fields
                this.currency = "";
                this.amount = "";
                this.v$.$reset();
                this.loading = true;
                document.getElementById("flexCheckDefault").click();
                document.getElementById("subs_close").click();
            } else {
                this.loading = true;
                this.$swal({
                    position: "top-end",
                    icon: "error",
                    title: response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 3000,
                });
            }
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },

        showdate(date) {
            var dd = this.padTo2Digits(date.getDate())
            var mm = this.padTo2Digits(date.getMonth() + 1)
            var final = dd + "/" + mm + "/" + date.getFullYear();
            return final;
        },
        Interest_period(period) {
            if (period == "D") {
                return "Daily";
            }
            if (period == "W") {
                return "Weekly";
            }
            if (period == "M") {
                return "Monthly";
            }
        },
        NullishCoalescing(a, b) {
            return a?.[b] ?? [];
        },
        parsedate(days) {
            var end_date = new Date().getDate() + days;
            var gg = new Date(parseFloat(new Date().setDate(end_date))).toLocaleDateString();
            var aa = gg.toString().split("/");
            return this.padTo2Digits(parseFloat(aa[1])) + "/" + this.padTo2Digits(parseFloat(aa[0])) + "/" + aa[2];
        },
        set_id(type, data, day = null) {
            if (day == null) {
                let dayy = data.o_plan_days[`${type}`][0];
                this.staking_plan_id = data.s_data?.[`${type}-${dayy}`]?.id;
                this.max_stake_amount =
                    data.s_data?.[`${type}-${dayy}`]?.max_stake_amount;
                this.min_stake_amount =
                    data.s_data?.[`${type}-${dayy}`]?.min_stake_amount;
            } else {
                this.staking_plan_id = data.s_data?.[`${type}-${day}`].id;
                this.max_stake_amount =
                    data.s_data?.[`${type}-${day}`].max_stake_amount;
                this.min_stake_amount =
                    data.s_data?.[`${type}-${day}`].min_stake_amount;
            }
        },

        setAmount() {
            let max_stake_amount = this.max_stake_amount;
            let available_amt = this.available_amt;
            let new_amt = Math.min(max_stake_amount, available_amt);
            this.amount = new_amt;
        },
        async redirectLog() {
            this.$router.push("/login");
        },
        setlogin() {
            this.redirectLog();
        },

        setmodeldata(data) {
            let stak_currency = data.stake_currency;
            this.getFunds(stak_currency);
            this.subsData = data;
            let selected_plan_type = this.subsData.selected_plan_type;
            let s_maturity_days = this.subsData.s_maturity_days;
            this.staking_plan_id = this.subsData.s_data?.[`${selected_plan_type}-${s_maturity_days}`].id;
            this.max_stake_amount = this.subsData.s_data?.[`${selected_plan_type}-${s_maturity_days}`].max_stake_amount;
            this.min_stake_amount = this.subsData.s_data?.[`${selected_plan_type}-${s_maturity_days}`].min_stake_amount;
            this.v$.$reset();


        },

        async getFunds(stak_currency) {
            let ress = await ApiClass.getNodeRequest("user-crypto/funds/get", true);
            if (ress.data.status_code == 1) {
                var find_funds = ress.data.data.find((el) => {
                    return el.currency == stak_currency;
                });
                this.available_amt = find_funds.balance;
            }
        },
        async getstakeWalletBalance() {
            const response = await ApiClass.getNodeRequest("staking/stake-wallet-balance", true);
            if (response.data.status_code == 1) {
                var udata = response.data.data;
                this.total_stake = udata[0].total_stake;
                this.total_withdrawable = udata[0].total_withdrawable;

            }

        },
    }, watch: {
        $route() {
            if (localStorage.getItem("token")) {
                this.loggedIn = true;
            }
        },
    }
}
</script>

<style scoped>
.staking h6 {
    font-size: 0.9rem !important;
    font-weight: normal;
}

.text-dark-yellow {
    color: var(--primary);
}

.up {
    color: rgb(21, 155, 110) !important;
}



tr {
    border-color: var(--line) !important;
}

thead {
    border-color: rgb(235, 235, 235) !important;

}


td:nth-child(1) {
    color: var(--text) !important;
    font-weight: 600;
    font-size: 0.9rem;
}

thead th {
    font-size: 0.9rem;
    color: var(--text);
}



.duration {
    background-color: #f2f2f2 !important;
}

.is_dark .stake-days {
    color: #f2f2f2 !important;
}

.is_dark .duration {
    background-color: transparent !important;
}

td div,
td,
td .duration {
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    color: var(--text);

}


.form-switch label {
    font-size: 0.9rem;
    color: rgb(86, 86, 86);
    font-weight: 600;
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}



.trade-pill {
    color: var(--primary) !important;

}




.seprator {
    color: rgb(205, 205, 205) !important;
    font-weight: 100 !important;
}

tr:hover .seprator {
    display: none !important;
}

.balance h4 {
    font-weight: 600 !important;
    color: rgb(84, 84, 84);
}



.balance h6 {
    font-weight: 600 !important;
    color: rgb(84, 84, 84);
}

.balance h5 {
    font-weight: 500 !important;
    color: rgb(129, 129, 129);
}

.balance h4 span {
    font-weight: 600 !important;
    font-size: 1.2rem;
}

.usd {
    background-color: rgb(204, 154, 2) !important;
}

.balance-btn span {
    background-color: rgb(204, 154, 2) !important;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--white) !important;
}

.subscribe-btn .badge {
    background-color: var(--primary);
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--white) !important;
}

.balance-btn {
    justify-content: end !important;
}


.balance-btn span:hover {
    transition: 0.37s;
    box-shadow: 0 .4rem 1rem rgba(0, 0, 0, .15) !important;
}

.convert span {
    font-size: 0.8rem !important;
    font-weight: 500;
}

.subscribe-dates h6 {
    font-weight: 700 !important;
}

.plan_active,
.day_active {
    position: relative;
    border: 2px solid var(--primary);
    color: var(--primary) !important;
}

.day_active::after {
    content: "\f058";
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-family: 'FontAwesome';
    font-size: 11px;
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 16px solid transparent;
    right: 12px;
    top: -2px;
}


@media screen and (max-device-width: 640px) {
    form {
        width: 100% !important;
    }

    #toggle-div2 {
        gap: 1.5rem !important;
    }

    #toggle-div1 {
        gap: 1rem !important;
    }

    #balance-div {
        padding-bottom: 2rem !important;
    }

    #balance-card1 {
        width: 100% !important;
        margin-top: 1rem;
    }


    .balance-btn span {
        margin-top: 2rem !important;
        padding: 0.5rem 0.8rem 0.5rem 0.8rem;
        justify-content: space-between !important;
    }

    .balance-btn {
        justify-content: space-between !important;
    }




}



.modal-body p {
    color: var(--text) !important;
}















.staking-table tbody tr td span {
    border: 1px solid var(--dark-bg);
    border-radius: 3px;
    font-size: 15px;

}

.staking-table tbody tr td span.plan_active {
    background: var(--dark-bg);
    border-radius: 3px;
    color: var(--white);
    font-size: 15px;
}

.staking-table tbody tr td span.day_active {
    background: var(--dark-bg);
    border-radius: 3px;
    color: var(--white);
}

.duration-btn button.btn.duration {
    width: 100%;
    border: 1px solid var(--dark-bg);
    min-width: 150px;
    text-transform: capitalize;
    color: var(--black);
}

.duration-btn button.btn.duration.flexible_btn {
    background-color: var(--dark-bg);
    color: var(--white);
}

ul.typelist li button.btn.type {
    width: 100%;
    border: 1px solid var(--dark-bg);
    min-width: 260px;
    text-transform: capitalize;
    color: var(--black);
}

ul.typelist li button.btn.type.flexible_btn {
    background-color: var(--dark-bg);
    color: var(--white);
}

/* staking background */
.staking_main {
    /* background: var(--dark-bg) url(../../assets/home_images/banner.png); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 800px;
}

.staking_main .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--dark-bg);
    color: var(--white);
    box-shadow: var(--box-shadow);
}

.staking_main .nav-pills .nav-link {
    font-weight: 500;
    color: var(--black);
    font-size: 15px;
    text-transform: uppercase;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    margin-right: 15px;
}

.my_staking a {
    color: var(--dark-bg);
    font-weight: 600;
    font-size: 15px;
}



.search_box .form-control:focus,
.modal-body .form-control:focus {
    box-shadow: none;
}

.input-group-text,
.modal-body .input-group-text {
    border: 1px solid transparent;
}

.search_box .input-group,
.modal-body .input-group {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: 0.375rem;
}

.staking-table thead tr th {
    font-size: 15px;
    font-weight: 600;
    color: var(--depth-table-head);
    white-space: nowrap;
}

.staking-table tbody tr td {
    font-size: 14px;
    color: var(--black);
    background: var(--staking-tr-bg);

}

.staking-table tbody tr {
    background: var(--staking-tr-bg);
    border-bottom: 12px solid var(--white);
}


.bg-light-yellow {
    background-color: var(--bg-light-yellow);
}

.modal-content,
.card {
    background-color: var(--bg);
}

.duration-btn button.btn.day {
    width: 100%;
    border: 1px solid var(--dark-bg);
    text-transform: capitalize;
    color: var(--black);
}

.duration-btn button.btn.day.flexible_btn {
    background: var(--dark-bg);
    padding: 6px 20px;
    font-weight: 500;
    font-family: "Poppins";
    border-radius: 4px;
    color: var(--white);
    cursor: pointer;
}


.modal-header {
    border-bottom: 2px solid var(--dark-bg);
    font-size: 18px;
}

.rotent-icon {
    transform: rotate(45deg);
}

.summary_list {
    position: relative;
}

.summary_list:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 2px;
    background-color: var(--signup-btn);
    top: 14px;
    left: 4px;
}

.border_bottom {
    border-bottom: 2px solid var(--dark-bg);
}

.green-text {
    color: var(--green);
}

.form-check-input:checked {
    background-color: var(--dark-bg);
    border-color: var(--dark-bg);
}

p.VuePagination__count.VuePagination__count {
    font-weight: 400;
}

.modal-header h4 {
    font-size: 16px;
    font-weight: 600;
}

.summary_list:nth-child(7):before {
    display: none;
}

.stake-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}</style>
